exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-knowledge-c-0-d-3-docs-add-exercise-docs-mdx": () => import("./../../../src/pages/knowledge/c0d3/docs/add_exercise_docs.mdx" /* webpackChunkName: "component---src-pages-knowledge-c-0-d-3-docs-add-exercise-docs-mdx" */),
  "component---src-pages-knowledge-c-0-d-3-docs-rewards-system-docs-mdx": () => import("./../../../src/pages/knowledge/c0d3/docs/rewards_system_docs.mdx" /* webpackChunkName: "component---src-pages-knowledge-c-0-d-3-docs-rewards-system-docs-mdx" */),
  "component---src-pages-knowledge-c-0-d-3-pages-add-exercise-mdx": () => import("./../../../src/pages/knowledge/c0d3/pages/add_exercise.mdx" /* webpackChunkName: "component---src-pages-knowledge-c-0-d-3-pages-add-exercise-mdx" */),
  "component---src-pages-knowledge-ds-stacks-and-queues-mdx": () => import("./../../../src/pages/knowledge/ds/stacks-and-queues.mdx" /* webpackChunkName: "component---src-pages-knowledge-ds-stacks-and-queues-mdx" */),
  "component---src-pages-knowledge-tech-aws-api-gateway-mdx": () => import("./../../../src/pages/knowledge/tech/aws/api-gateway.mdx" /* webpackChunkName: "component---src-pages-knowledge-tech-aws-api-gateway-mdx" */),
  "component---src-pages-knowledge-tech-aws-api-gateway-with-auth-mdx": () => import("./../../../src/pages/knowledge/tech/aws/api-gateway-with-auth.mdx" /* webpackChunkName: "component---src-pages-knowledge-tech-aws-api-gateway-with-auth-mdx" */),
  "component---src-pages-knowledge-tech-aws-api-gateway-with-cloudwatch-mdx": () => import("./../../../src/pages/knowledge/tech/aws/api-gateway-with-cloudwatch.mdx" /* webpackChunkName: "component---src-pages-knowledge-tech-aws-api-gateway-with-cloudwatch-mdx" */),
  "component---src-pages-knowledge-tech-aws-appconfig-mdx": () => import("./../../../src/pages/knowledge/tech/aws/appconfig.mdx" /* webpackChunkName: "component---src-pages-knowledge-tech-aws-appconfig-mdx" */),
  "component---src-pages-knowledge-tech-aws-cdk-and-cloudformation-mdx": () => import("./../../../src/pages/knowledge/tech/aws/cdk-and-cloudformation.mdx" /* webpackChunkName: "component---src-pages-knowledge-tech-aws-cdk-and-cloudformation-mdx" */),
  "component---src-pages-knowledge-tech-aws-codepipeline-mdx": () => import("./../../../src/pages/knowledge/tech/aws/codepipeline.mdx" /* webpackChunkName: "component---src-pages-knowledge-tech-aws-codepipeline-mdx" */),
  "component---src-pages-knowledge-tech-aws-cross-account-access-with-iam-roles-mdx": () => import("./../../../src/pages/knowledge/tech/aws/cross-account-access-with-iam-roles.mdx" /* webpackChunkName: "component---src-pages-knowledge-tech-aws-cross-account-access-with-iam-roles-mdx" */),
  "component---src-pages-knowledge-tech-aws-ec-2-mdx": () => import("./../../../src/pages/knowledge/tech/aws/ec2.mdx" /* webpackChunkName: "component---src-pages-knowledge-tech-aws-ec-2-mdx" */),
  "component---src-pages-knowledge-tech-aws-iam-mdx": () => import("./../../../src/pages/knowledge/tech/aws/iam.mdx" /* webpackChunkName: "component---src-pages-knowledge-tech-aws-iam-mdx" */),
  "component---src-pages-knowledge-tech-aws-iam-principal-mdx": () => import("./../../../src/pages/knowledge/tech/aws/iam-principal.mdx" /* webpackChunkName: "component---src-pages-knowledge-tech-aws-iam-principal-mdx" */),
  "component---src-pages-knowledge-tech-aws-index-mdx": () => import("./../../../src/pages/knowledge/tech/aws/index.mdx" /* webpackChunkName: "component---src-pages-knowledge-tech-aws-index-mdx" */),
  "component---src-pages-knowledge-typescript-mdx": () => import("./../../../src/pages/knowledge/typescript.mdx" /* webpackChunkName: "component---src-pages-knowledge-typescript-mdx" */)
}

