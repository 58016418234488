import React, { PropsWithChildren, ReactElement, useRef, useState } from 'react'
import { MDXProvider } from '@mdx-js/react'
import styled from 'styled-components'
import vsLight from 'prism-react-renderer/themes/nightOwl'
import Highlight, { defaultProps } from 'prism-react-renderer'
import { GlobalHead } from '../../utils/GlobalHead'
import Breadcrumb from '../Breadcrumb/Breadcrumb'
import { Button, ThemeProvider, createTheme } from '@mui/material'
import './layout.css'
import { Link } from 'gatsby'

const H1Styled = styled.h1`
  border-bottom: 3px solid;
  padding-bottom: 10px;
  color: #d3d3d3;
`

const PreStyled = styled.pre`
  background: hsl(0deg 0% 98%);
  border-left: 3px solid cornflowerblue;
  color: hsl(0deg 0% 40%);
  page-break-inside: avoid;
  font-family: 'fira code';
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
  white-space: pre-wrap;
`
const CodeStyled = styled.code`
  font-family: 'fira code';
`

const InlineCodeMDX = styled.code`
  font-family: 'fira code';
  font-size: 12px;
  color: #bdc6cf;
  padding: 3px 8px;
  border: 0;
  background-color: #282c34;
  border-radius: 4px;
  margin-inline: 2px;
  font-weight: bold;
`
const AnchorMDX = styled.a`
  color: #527dc9;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
const copyCode = async (elem: Element | null) => {
  const selection = window.getSelection()

  // Save the current selection
  const currentRange =
    selection?.rangeCount === 0 ? null : selection?.getRangeAt(0)

  try {
    if (!elem) return

    // Select the text content of code element
    const range = document.createRange()
    range.selectNodeContents(elem)
    selection?.removeAllRanges()
    selection?.addRange(range)

    // Copy to the clipboard
    await navigator.clipboard.writeText(selection?.toString() || '')
  } catch (e) {
    return e
  } finally {
    selection?.removeAllRanges()
    currentRange && selection?.addRange(currentRange)

    return selection?.toString()
  }
}

const component = {
  pre: (props: ReactElement['props']) => {
    const className = props.children.props.className || ''
    const matches = className.match(/language-(?<lang>.*)/)
    const [copied, setCopied] = useState(false)

    const ref = useRef(null)

    return (
      <div>
        <Button
          variant="outlined"
          onClick={async () => {
            const value = await copyCode(ref.current)

            if (typeof value === 'string') {
              setCopied(true)
              setTimeout(() => setCopied(false), 2000)
            } else {
              setCopied(false)
            }
          }}
        >
          {copied ? 'Copied to clipboard!' : 'Copy'}
        </Button>
        <Highlight
          {...defaultProps}
          theme={vsLight}
          code={props.children.props.children}
          language={matches?.groups?.lang ? matches.groups.lang : ''}
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <PreStyled className={className} style={style} ref={ref}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  {line.map((token, key) =>
                    // If the last line is empty, don't render anything
                    i === tokens.length - 1 ? (
                      token.content.trim().length ? (
                        <span {...getTokenProps({ token, key })} />
                      ) : (
                        <></>
                      )
                    ) : (
                      <span {...getTokenProps({ token, key })} />
                    )
                  )}
                </div>
              ))}
            </PreStyled>
          )}
        </Highlight>
      </div>
    )
  },
}

const CodeMDX = (props: ReactElement['props']) => <CodeStyled {...props} />

export const CustomAnchorMDX = (props: ReactElement['props']) => {
  const isLocalAnchor =
    props?.className?.includes('anchor') || !props.href.includes('://')
  // _self means the link is a bookmark link or local link to different page
  // _blank means the link is external
  const target = isLocalAnchor ? '_self' : '_blank'

  const Element = (props: any) => {
    return isLocalAnchor ? (
      <Link className="local-anchor" to={props.href} {...props} />
    ) : (
      <AnchorMDX {...props} />
    )
  }

  return <Element {...props} target={target} />
}

const LayoutContainer = styled.div`
  display: grid;
  justify-content: center;
  margin: calc(1vh * 8) calc(1vw * 2);
`

const BreadCrumbContainer = styled.div`
  // for the headings bookmark icon to appear
  padding-left: 30px;
`

const theme = createTheme({
  typography: {
    fontFamily: ['-apple-system', 'Inter', 'Roboto'].join(','),
  },
  palette: {
    text: {
      primary: '#d3d3d3',
      secondary: '#afafaf',
    },
  },
})

const Layout: React.FC<
  PropsWithChildren<{ title: string; location?: any }>
> = ({ children, title, location }) => {
  return (
    <LayoutContainer>
      <GlobalHead title={title}>
        <ThemeProvider theme={theme}>
          <BreadCrumbContainer>
            <Breadcrumb location={location} />
          </BreadCrumbContainer>
          <MDXProvider
            components={{
              pre: component.pre,
              code: CodeMDX,
              inlineCode: InlineCodeMDX,
              a: CustomAnchorMDX,
              h1: H1Styled,
            }}
          >
            {children}
          </MDXProvider>
        </ThemeProvider>
      </GlobalHead>
    </LayoutContainer>
  )
}

export default Layout
